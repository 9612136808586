import { locationService } from '@grafana/runtime';

export function onsSetParam(VariableName: string, NewValue: any) {
  // Code based and Groot Grafana help ;)
  // https://web.archive.org/web/20230802094511/https://grafana.com/docs/grafana/latest/developers/plugins/create-a-grafana-plugin/extend-a-plugin/add-support-for-variables/
  // console.info(`onSoft - Bazinga! Variable "${variableName}" `);

  if (!VariableName || !NewValue) {
    throw new Error('Variable name and new value are required.');
  }

  const { partial } = locationService;

  try {
    partial({ [VariableName]: NewValue }, true);
  } catch (error) {
    console.error('Error updating variable:', error);
  }
}

export async function onsSetTimeRange(startTime: any, endTime: any) {
  onsSetParam('from',startTime);
  onsSetParam('to',endTime);
}

/*
export async function onsSetTimeRange(startTime, endTime) {
  const dashboardSrv = getDashboardSrv();
  const dashboard = await dashboardSrv.getCurrent();

  if (dashboard) {
    dashboard.time.from = startTime;
    dashboard.time.to = endTime;
    dashboardSrv.setTime(dashboard.time);
  }
}


export function onsSetTimeRangeV3(startTime, endTime) {
  const range = {
    from: startTime,
    to: endTime,
  };

  updateLocation({ query: { ...range } });
}

export async function onsSetTimeRangeV1(startTime, endTime) {
  const timeSrv = getTimeSrv();
  timeSrv.setTime({
    from: startTime,
    to: endTime,
  });
} 

export async function onsSetTimeRangeV2(startTime, endTime) {
  const timeSrv = await getBackendSrv().getTimeSrv();
  timeSrv.setTime({
    from: startTime,
    to: endTime,
  });
} 
*/
