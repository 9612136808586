// ONSOFT.AJR
import { onsSetParam } from '../../../../../public/onsoft/lib/utils';

import { TableCellProps } from './types';

export const ButtonVarCell = (props: TableCellProps) => {
  const { cell, tableStyles, cellProps } = props;

  const lMyString = cell.value;
  const lparts = lMyString.split('|');
  const lVar = lparts[0]; // Var
  const lId = lparts[1]; // Id
  const lText = lparts[2]; // Id
  return (
   <div {...cellProps} className={tableStyles.cellContainer}>
      {<button onClick={() => { onsSetParam(lVar,lId); }} className="ons-button-var">{lText}</button>}
    </div>
  );
};
